import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/**
 * This component is used to render the blog page.
 *
 * @param {object} data The data object returned from the graphql query.
 * @returns {*} The page component.
 */
const ContentfulPage = ({ data }) => {
  // destructure the nodes from the data
  const { nodes } = data.allContentfulPageBlogPost;

  return (
    // return a fragment
    <>
      {/* render the page wrapper component */}
      <PageWrapper
        // pass the header and footer props
        headerConfig={{
          // use light theme
          theme: "light",
          // set the button text
          buttonText: "Contact us",
        }}
        footerConfig={{
          // use light theme
          theme: "light",
          // set the page
          page: "akkadian-home",
          // set the style
          style: "style2", //style1, style2
        }}
      >
        {/* render the helmet component */}
        <Helmet>
          {/* set the page title */}
          <title>Akkadian Health - Blog | ADHD and Mental Health</title>
          <meta
            name="description"
            content="Stay updated with the latest news, articles, and insights on ADHD and mental health from Akkadian Health’s experts."
          />          
        </Helmet>
        {/* render the inner banner container */}
        <div className="inner-banner bg-default-2">
          <Container>
            {/* render the row and column */}
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                {/* render the text */}
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h1 className="title gr-text-3 mb-9 mb-lg-12">ADHD and Mental Health News Articles</h1>
                  <h3 className="gr-text-6 mb-6">
                    Check out some of the latest research, news and updates on ADHD, mental health and all things digital from the team at Akkadian Health.
                  </h3>
                </div>
              </Col>
            </Row>

            {/* render the row and column */}
            <Row className="justify-content-center mt-md-3 pt-12 pt-lg-14 ">
              {/* loop over the nodes */}
              {nodes.map((post) => (
                // render the column
                <Col key={post.id} md={6} lg={4}>
                  {/* render the card */}
                  <Card className="rounded-10 mb-7" style={{ height: "580px" }}>
                    {/* render the image */}
                    <div
                      style={{
                        height: "calc(280px * 580 / 580)",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <GatsbyImage
                        image={getImage(post.featuredImage)}
                        alt={post.featuredImage.title}
                        style={{ height: "100%" }}
                        className="w-100 rounded-top-10"
                      />
                    </div>
                    {/* render the card body */}
                    <Card.Body style={{ height: "calc(300px * 580 / 580)" }}>
                      {/* render the title */}
                      <Card.Title className="mb-5">
                        <Link to={`/${post.slug}`}>{post.title}</Link>
                      </Card.Title>

                      {/* render the short description */}
                      <Card.Text className="mb-6">
                        {post.shortDescription.shortDescription}
                      </Card.Text>
                      
                      {/* render the author and date */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 12,
                          right: 6,
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <p className="mt-7 card-date text-muted small mb-4 mr-2">
                        <span className="text-dark">Published:</span> {post.publishedDate}
                        </p>
                      </div>
                      <div style={{ position: "absolute", bottom: 12 }}>
                        <p className="mt-7 card-date text-muted small d-flex justify-content-between">
                          <span className="d-flex align-items-center">
                            <GatsbyImage
                    image={getImage(post.author.avatar)}
                              className="rounded-circle"
                              style={{ width: "30px", height: "30px" }}
                              alt={post.author.name}
                            />
                            <span className="text-muted small pl-3" style={{ fontSize: "0.6rem" }}>
                              {post.author.name.toUpperCase()}
                            </span>
                          </span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>{" "}
            {/* close the row */}
          </Container>{" "}
          {/* close the container */}
        </div>
        {/* close the inner banner */}
      </PageWrapper>
    </>
  );
};

const options = {
  renderText: text => {
    return (
      <p key={text.split(' ').join('')}>{text}</p>
    );
  }
};


export const query = graphql`
  query {
    allContentfulPageBlogPost(filter: { node_locale: { eq: "en-US" } }){
      nodes {
        id
        internalName
        seoFields {
          pageTitle
          nofollow
          noindex
        }
        slug
        author {
          name
          avatar {
            gatsbyImageData(width: 200)
            file {
              url
            }
          }          
        }
        publishedDate(formatString: "MMM Do, YYYY")
        title
        shortDescription {
          shortDescription
        }
        featuredImage {
          title
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
        content {
          raw
        }
      }
    }
  }
`;

export default ContentfulPage;
